import { reactive, computed, watch  } from 'vue'
import { useRouter } from 'vue-router'
import store from '@/store/index.js'


export function useCommercial() {
    const router = useRouter()

    const commercialData = computed(
        () => store.getters.getCommercialData
    )

    const commercialFormErrors = computed(
        () => store.getters.getCommercialFormErrors
    )

    const showLoader = reactive({
        value: false
    })

    async function getCommercialData(code) {
        this.showLoader.value = true
        await store
            .dispatch('getCommercialData', code)
            .then(
                () => {
                    this.showLoader.value = false
                }
            )
    }

    function saveCommercialData() {
        this.showLoader.value = true
        store
            .dispatch('saveCommercialData', this.commercialData.value)
            .then(
                () => {
                    this.showLoader.value = false
                }
            )
    }

    function addCommercialData() {
        this.showLoader.value = true
        console.log("DATOS A ENVIAR",this.commercialData.value);
        store
            .dispatch('addCommercialData', this.commercialData.value)
            .then(
                () => {
                    this.showLoader.value = false

                }
            )
    }

    function clearCommercialData() {
        this.showLoader.value = true

        store.dispatch('clearCommercialData').then(
            () => {
                this.showLoader.value = false
            }
        )
    }

    return {
        commercialData,
        showLoader,
        getCommercialData,
        saveCommercialData,
        addCommercialData,
        commercialFormErrors,
        clearCommercialData,
    }
}