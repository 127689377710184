import { reactive, computed, watch  } from 'vue'
import { useRouter } from 'vue-router'
import store from '@/store/index.js'
import router from "@/router";
import ClientsManagementService from "@/services/Clients/ClientsManagementService";


export function useClient() {
    const router = useRouter()

    const clientData = computed(
        () => store.getters.getClientData
    )

    const clientFormErrors = computed(
        () => store.getters.getClientFormErrors
    )

    const showLoader = reactive({
        value: false
    })

    async function getClientData(code) {
        this.showLoader.value = true
        await store
            .dispatch('getClientData', code)
            .then(
                () => {
                    this.showLoader.value = false
                }
            )
    }

    function saveClientData() {
        this.showLoader.value = true
        store
            .dispatch('saveClientData', this.clientData.value)
            .then(
                () => {
                    this.showLoader.value = false
                }
            )
    }

    function addClientData() {
        this.showLoader.value = true
        console.log("DATOS A ENVIAR",this.clientData.value);
        store
            .dispatch('addClientData', this.clientData.value)
            .then(
                () => {
                    this.showLoader.value = false

                }
            )
    }

    function clearClientData() {
        this.showLoader.value = true

        store.dispatch('clearClientData').then(
            () => {
                this.showLoader.value = false
            }
        )
    }

    function updateCommercial(commercialId) {
        this.showLoader.value = true
        store
            .dispatch('updateCommercial', {
                commercialId: commercialId,
                clientId: clientData.value.id
            })
            .then(
                () => {
                    this.showLoader.value = false
                }
            )
    }

    function addNewSale(sale) {
        this.showLoader.value = true
        store
            .dispatch('addNewSale', sale)
            .then(
                () => {
                    this.showLoader.value = false
                }
            )
    }

    function updateSale(sale) {
        this.showLoader.value = true
        store
            .dispatch('updateSale', sale)
            .then(
                () => {
                    this.showLoader.value = false
                }
            )
    }

    function deleteSale(saleData) {
        this.showLoader.value = true
        store
            .dispatch('deleteSale', saleData)
            .then(
                () => {
                    this.showLoader.value = false
                }
            )
    }

    async function downloadInvoice(saleId) {

        try {

            this.showLoader.value = true;
            const res = await ClientsManagementService.downloadInvoice(saleId);
            this.showLoader.value = false;

            // Crear un objeto URL a partir de los datos del Blob
            const url = window.URL.createObjectURL(new Blob([res.data]));

            const a = document.createElement('a');
            a.href = url;
            a.download = 'factura.pdf';
            a.click();
            window.URL.revokeObjectURL(url);

            let notification = [
                {
                    message: "Factura descargada",
                    type: "success",
                },
            ];

            store.dispatch("sendNotification", notification);
            store.dispatch("removeNotificationFromTimeout");
            return res;
        } catch (error) {
            this.showLoader.value = false;

            let notification = [
                {
                    message: "Error,factura no generada",
                    type: "error",
                },
            ];

            store.dispatch("sendNotification", notification);
            store.dispatch("removeNotificationFromTimeout");

            return error;
        }
    }

    return {
        clientData,
        showLoader,
        getClientData,
        saveClientData,
        addClientData,
        clearClientData,
        clientFormErrors,
        updateCommercial,
        addNewSale,
        updateSale,
        deleteSale,
        downloadInvoice,
    }
}