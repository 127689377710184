import ProvidersManagementService from "../../../services/Providers/ProvidersManagementService";
import ClientsManagementService from "@/services/Clients/ClientsManagementService";

const getDefaultState = () => {
    return {
        id: null,
        email: '',
        telefono: '',
        nombre: '',
        cif: '',
        direccion: '',
        poblacion: '',
        provincia: '',
        cp: '',
        contacto: '',
    };
};

export default {
    state: {
        data: getDefaultState(),
        errorsForm: '',
    },
    mutations: {
        SET_PROVIDER_DATA(state, providerData) {
            state.data = providerData
        },
        SET_PROVIDER_FORM_ERRORS(state, ProviderErrorsForm) {
            state.errorsForm = ProviderErrorsForm
        },
        CLEAR_ERRORS(state) {
            state.errorsForm = ''
        },

    },
    actions: {
        getProviderData({ commit }, code) {
            return ProvidersManagementService.getProviderData(code)
        },
        saveProviderData({ commit }, providerData) {
            return ProvidersManagementService.saveProviderData(providerData)
        },
        addProviderData({ commit }, providerData) {
            return ProvidersManagementService.addProvider(providerData)
        },
        storeProvider({ commit }, ProviderObject) {
            commit('SET_PROVIDER_DATA', ProviderObject)
        },
        clearErrors({ commit }) {
            commit('CLEAR_ERRORS')
        },
        storeProviderFormErrors({ commit }, providerErrorsForm) {
            console.log("storeProviderFormErrors",providerErrorsForm);
            commit('SET_PROVIDER_FORM_ERRORS', providerErrorsForm)
        },
        addNewOrder({ commit }, orderData) {
            return ProvidersManagementService.addNewOrder(orderData)
        },
        updateOrder({ commit }, orderData) {
            return ProvidersManagementService.updateOrder(orderData)
        },
        deleteOrder({ commit }, id) {
            return ProvidersManagementService.deleteOrder(id)
        },
    },
    getters: {
        getProviderData(state) {
            console.log('PROVIDER GETTER', state.data)
            return state.data
        },
        getProviderFormErrors(state) {
            console.log('PROVIDER FORM ERRORS', state.errorsForm)
            return state.errorsForm
        },

    }
};