import OrdersManagementService from "../../../services/Orders/OrdersManagementService";

const getDefaultState = () => {
    return {
        id: null,
        email: '',
        telefono: '',
        nombre: '',
        dni: '',
        direccion: '',
        poblacion: '',
        provincia: '',
        cp: '',
    };
};

export default {
    state: {
        data: getDefaultState(),
        errorsForm: '',
    },
    mutations: {
        SET_ORDER_DATA(state, orderData) {
            state.data = orderData
        },
        SET_ORDER_FORM_ERRORS(state, OrderErrorsForm) {
            state.errorsForm = OrderErrorsForm
        },
        CLEAR_ERRORS(state) {
            state.errorsForm = ''
        },

    },
    actions: {
        getOrderData({ commit }, code) {
            return OrdersManagementService.getOrderData(code)
        },
        saveOrderData({ commit }, OrderData) {
            return OrdersManagementService.saveOrderData(orderData)
        },
        addOrderData({ commit }, orderData) {
            return OrdersManagementService.addOrder(orderData)
        },
        storeOrder({ commit }, OrderObject) {
            commit('SET_ORDER_DATA', OrderObject)
        },
        clearErrors({ commit }) {
            commit('CLEAR_ERRORS')
        },
        storeOrderFormErrors({ commit }, orderErrorsForm) {
            console.log("storeOrderFormErrors",orderErrorsForm);
            commit('SET_ORDER_FORM_ERRORS', orderErrorsForm)
        },
    },
    getters: {
        getOrderData(state) {
            console.log('ORDER GETTER', state.data)
            return state.data
        },
        getOrderFormErrors(state) {
            console.log('ORDER FORM ERRORS', state.errorsForm)
            return state.errorsForm
        },

    }
};