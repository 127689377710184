<template>
  <teleport to="body">
    <Loader v-if="provider.showLoader.value" />
  </teleport>
  <header class="bg-white shadow-sm">
    <div class="flex max-w-full mx-auto py-4 px-4 sm:px-6 lg:px-8">
      <h1 class="text-lg leading-6 font-semibold text-gray-900">
        Gestión de Proveedores
      </h1>
    </div>
  </header>


  <div class="container m-auto grid grid-cols-3 gap-4 py-4">
    <div class="col-span-3 md:col-span-3 lg:col-span-2">
      <form @submit.prevent="provider.addProviderData()">
        <div class="overflow-hidden bg-white shadow sm:rounded-lg">
          <div class="container m-auto grid grid-cols-3 gap-4 px-4 py-5 sm:px-6">
            <div class="col-span-2">
              <h3 class="text-base font-semibold leading-6 text-gray-900">Datos Proveedor</h3>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">Información y detalles</p>
            </div>

            <div class="col-span-1 justify-self-end">
              <button type="submit" class="h-50 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                Crear
              </button>
            </div>
          </div>

          <div class="border-t border-gray-200">
            <dl>

              <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500 py-3">Nombre</dt>
                <input required
                    class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                    type="text" v-model="provider.providerData.value.nombre" />
                <dt v-if="provider.providerFormErrors.value.nombre" class="text-sm font-medium text-gray-500 py-3 text-red-600">
                  {{ provider.providerFormErrors.value.nombre[0] }}
                </dt>
              </div>
              <div class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500 py-3">CIF</dt>
                <input required
                    class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                    type="text" v-model="provider.providerData.value.cif"  maxlength="11" />
                <dt v-if="provider.providerFormErrors.value.cif" class="text-sm font-medium text-gray-500 py-3 text-red-600">
                  {{ provider.providerFormErrors.value.cif[0] }}
                </dt>
              </div>
              <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500 py-3">Domicilio</dt>
                <input required
                    class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                    type="text" v-model="provider.providerData.value.direccion" />
                <dt v-if="provider.providerFormErrors.value.direccion" class="text-sm font-medium text-gray-500 py-3 text-red-600">
                  {{ provider.providerFormErrors.value.direccion[0] }}
                </dt>
              </div>
              <div class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500 py-3">C.P.</dt>
                <input required
                    class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                    type="text" v-model="provider.providerData.value.cp" />
                <dt v-if="provider.providerFormErrors.value.cp" class="text-sm font-medium text-gray-500 py-3 text-red-600">
                  {{ provider.providerFormErrors.value.cp[0] }}
                </dt>
              </div>
              <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500 py-3">Población</dt>
                <input required
                    class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                    type="text" v-model="provider.providerData.value.poblacion" />
                <dt v-if="provider.providerFormErrors.value.poblacion" class="text-sm font-medium text-gray-500 py-3 text-red-600">
                  {{ provider.providerFormErrors.value.poblacion[0] }}
                </dt>
              </div>
              <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500 py-3">Provincia</dt>
                <input required
                    class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                    type="text" v-model="provider.providerData.value.provincia" />
                <dt v-if="provider.providerFormErrors.value.provincia" class="text-sm font-medium text-gray-500 py-3 text-red-600">
                  {{ provider.providerFormErrors.value.provincia[0] }}
                </dt>
              </div>
              <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500 py-3">Teléfono</dt>
                <input required
                    class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                    type="text" v-model="provider.providerData.value.telefono" />
                <dt v-if="provider.providerFormErrors.value.telefono" class="text-sm font-medium text-gray-500 py-3 text-red-600">
                  {{ provider.providerFormErrors.value.telefono[0] }}
                </dt>
              </div>

              <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500 py-3">Email</dt>
                <input required
                    class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                    type="text" v-model="provider.providerData.value.email" />
                <dt v-if="provider.providerFormErrors.value.email" class="text-sm font-medium text-gray-500 py-3 text-red-600">
                  {{ provider.providerFormErrors.value.email[0] }}
                </dt>
              </div>

              <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500 py-3">Contacto</dt>
                <input required
                    class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                    type="text" v-model="provider.providerData.value.contacto" />
                <dt v-if="provider.providerFormErrors.value.contacto" class="text-sm font-medium text-gray-500 py-3 text-red-600">
                  {{ provider.providerFormErrors.value.contacto[0] }}
                </dt>
              </div>
            </dl>
          </div>
        </div>
      </form>
    </div>

    <div class="hidden col-span-3 md:col-span-3 lg:col-span-1">
      <div class=" bg-white shadow sm:rounded-lg">
        <div class="container m-auto grid grid-cols-3 gap-4 px-4 py-5 sm:px-6">
          <div class="col-span-1 justify-self-end">
            <button type="submit"
                    class="h-50 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
              Crear
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>


</template>

<script>
import { useProvider } from "@/use/provider";
import { defineAsyncComponent, ref } from "vue";

const Loader = defineAsyncComponent(() => import("@/components/Common/Loader/Loader.vue"))
import CardWithHeaderComponent from "@/components/Layout/Panels/CardWithHeaderComponent";
import ToggleComponent from "../../components/Common/Form/ToggleComponent";
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import Pagination from "../../components/Pagination";
import Notification from "@/App.vue";
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
import CustomSelectInput from "../../components/Common/Select/CustomSelectInput";
import moment from "moment";

export default {
  name: "providerAdd",
  components: {
    Notification,
    Pagination,
    Loader,
    CardWithHeaderComponent,
    ToggleComponent,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    CustomSelectInput,
    Listbox, ListboxButton, ListboxOption, ListboxOptions,

  },
  setup(props, context) {
    const provider = useProvider();
    const selectedTab = ref('edit');


    return {
      provider,
      selectedTab,
    }
  },
  data() {
    return {
      formatter: new Intl.NumberFormat('es-ES', {
        style: 'currency',
        currency: 'EUR'
      }),
    }
  },
  methods: {
    save() {
      this.provider.saveProviderData()
    },

    getDateInFormat(date) {
      return moment(date).format('DD/MM/YYYY HH:mm');
    },
  },
  async created() {
  },
  mounted() {
  },
}
</script>

<style scoped></style>