import { createStore } from 'vuex'
import notification from '@/store/modules/notification.js'
import user from '@/store/modules/user.js'
import users from '@/store/modules/users.js'
import sales from '@/store/modules/Sales/sales.js'
import sale from '@/store/modules/Sales/sale.js'
import clients from '@/store/modules/Clients/clients.js'
import client from '@/store/modules/Clients/client.js'
import commercials from '@/store/modules/Commercials/commercials.js'
import commercial from '@/store/modules/Commercials/commercial.js'
import providers from '@/store/modules/Providers/providers.js'
import provider from '@/store/modules/Providers/provider.js'
import orders from '@/store/modules/Orders/orders.js'
import order from '@/store/modules/Orders/order.js'
import roles from '@/store/modules/roles.js'
export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    notification,
    user,
    users,
    sales,
    sale,
    clients,
    client,
    commercials,
    commercial,
    providers,
    provider,
    orders,
    order,
    roles,
  }
})
