import CommercialsManagementService from "../../../services/Commercials/CommercialsManagementService";

const getDefaultState = () => {
    return {
        id: null,
        email: '',
        telefono: '',
        nombre: '',
        dni: '',
        direccion: '',
        poblacion: '',
        provincia: '',
        cp: '',
    };
};

export default {
    state: {
        data: getDefaultState(),
        errorsForm: '',
    },
    mutations: {
        SET_COMMERCIAL_DATA(state, CommercialData) {
            state.data = CommercialData
        },
        SET_COMMERCIAL_FORM_ERRORS(state, CommercialErrorsForm) {
            state.errorsForm = CommercialErrorsForm
        },
        CLEAR_ERRORS(state) {
            state.errorsForm = ''
        },
    },
    actions: {
        getCommercialData({ commit }, code) {
            return CommercialsManagementService.getCommercialData(code)
        },
        saveCommercialData({ commit }, CommercialData) {
            return CommercialsManagementService.saveCommercialData(CommercialData)
        },
        addCommercialData({ commit }, CommercialData) {
            return CommercialsManagementService.addCommercial(CommercialData)
        },
        storeCommercial({ commit }, CommercialObject) {
            commit('SET_COMMERCIAL_DATA', CommercialObject)
        },
        clearErrors({ commit }) {
            commit('CLEAR_ERRORS')
        },
        storeCommercialFormErrors({ commit }, CommercialErrorsForm) {
            console.log("storeCommercialFormErrors",CommercialErrorsForm);
            commit('SET_COMMERCIAL_FORM_ERRORS', CommercialErrorsForm)
        },
        clearCommercialData({ commit }){
            commit('SET_COMMERCIAL_DATA', getDefaultState())
        }
    },
    getters: {
        getCommercialData(state) {
            console.log('COMMERCIAL GETTER', state.data)
            return state.data
        },
        getCommercialFormErrors(state) {
            console.log('COMMERCIAL FORM ERRORS', state.errorsForm)
            return state.errorsForm
        },

    }
};