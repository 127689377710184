import { reactive, computed } from 'vue'
import { useRouter } from 'vue-router'
import store from '@/store/index.js'

export function useSales() {
    const router = useRouter()

    const salesData = computed(
        () => store.getters.getSalesData
    )

    const filters = reactive({
        id: '',
        nombre: '',
        poblacion: '',
        telefono: '',
        dni: '',
        page: 1,
        email: ''
    })

    const showLoader = reactive({
        value: false
    })
    function searchSalesByFilters(page = 1){
        this.showLoader.value = true
        this.filters.page = page

        store
            .dispatch('getSalesByFilters', filters)
            .then(
                () => {
                    this.showLoader.value = false
                }
            )
    }


    return {
        salesData,
        filters,
        showLoader,
        searchSalesByFilters,
    }
}