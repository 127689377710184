import SalesManagementService from "../../../services/Sales/SalesManagementService";

const getDefaultState = () => {
    return {
        id: null,
        email: '',
        telefono: '',
        nombre: '',
        dni: '',
        direccion: '',
        poblacion: '',
        provincia: '',
        cp: '',
    };
};

export default {
    state: {
        data: getDefaultState(),
        errorsForm: '',
    },
    mutations: {
        SET_SALE_DATA(state, saleData) {
            state.data = saleData
        },
        SET_SALE_FORM_ERRORS(state, SaleErrorsForm) {
            state.errorsForm = SaleErrorsForm
        },
        CLEAR_ERRORS(state) {
            state.errorsForm = ''
        },

    },
    actions: {
        getSaleData({ commit }, code) {
            return SalesManagementService.getSaleData(code)
        },
        saveSaleData({ commit }, SaleData) {
            return SalesManagementService.saveSaleData(saleData)
        },
        addSaleData({ commit }, saleData) {
            return SalesManagementService.addSale(saleData)
        },
        storeSale({ commit }, SaleObject) {
            commit('SET_SALE_DATA', SaleObject)
        },
        clearErrors({ commit }) {
            commit('CLEAR_ERRORS')
        },
        storeSaleFormErrors({ commit }, saleErrorsForm) {
            console.log("storeSaleFormErrors",saleErrorsForm);
            commit('SET_SALE_FORM_ERRORS', saleErrorsForm)
        },
    },
    getters: {
        getSaleData(state) {
            console.log('SALE GETTER', state.data)
            return state.data
        },
        getSaleFormErrors(state) {
            console.log('SALE FORM ERRORS', state.errorsForm)
            return state.errorsForm
        },

    }
};