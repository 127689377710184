import customAxios from '@/axios/index'
import store from '@/store'
import errorReporting from '@/services/ErrorReporting.js'
import router from '@/router';

const apiClient = customAxios


export default {
    index(filters){
        return apiClient
            .post('/commercials',filters)
            .then(({ data }) => {
                console.log("Respuesta Api ", data)
                store.dispatch('storeCommercials', data)
            })
            .catch(error => {
                errorReporting.showErrorResponseFromAPI(error)
            })
    },

    getCommercialData(code){
        let path = '/commercial/' + code

        return apiClient
            .get(path)
            .then(({ data }) => {
                console.log("Respuesta Api ", data)
                store.dispatch('storeCommercial', data)
            })
            .catch(error => {
                errorReporting.showErrorResponseFromAPI(error)
            })
    },

    saveCommercialData(commercialData){
        return apiClient
            .post('/commercial/update',commercialData)
            .then(({ data }) => {
                console.log("Respuesta Api ", data)
                let notification = [
                    {
                        'message': 'Comercial actualizado correctamente',
                        'type': "success"
                    }
                ]

                store.dispatch('sendNotification',  notification)
                store.dispatch('removeNotificationFromTimeout')
                store.dispatch('clearErrors')
            })
            .catch(error => {
                store.dispatch('storeCommercialFormErrors', error.response.data.errors)
            })
    },

    addCommercial(commercialData){
        return apiClient
            .post('/commercial/add',commercialData)
            .then(({ data }) => {
                let notification = [
                    {
                        'message': 'Comercial creado correctamente',
                        'type': "success"
                    }
                ]
                store.dispatch('sendNotification', notification);

                const commercialId = data.data.id;
                router.push(`/commercials/edit/${commercialId}`);
                store.dispatch('removeNotificationFromTimeout');
                store.dispatch('clearErrors')

            })
            .catch(error => {
                store.dispatch('storeCommercialFormErrors', error.response.data.errors)
            })
    },

}

