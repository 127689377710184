import customAxios from '@/axios/index'
import store from '@/store'
import errorReporting from '@/services/ErrorReporting.js'
import router from '@/router';

const apiClient = customAxios


export default {
    index(filters){
        return apiClient
            .post('/orders',filters)
            .then(({ data }) => {
                console.log("Respuesta Api ", data)
                store.dispatch('storeOrders', data)
            })
            .catch(error => {
                errorReporting.showErrorResponseFromAPI(error)
            })
    },

    getOrderData(code){
        let path = '/order/' + code

        return apiClient
            .get(path)
            .then(({ data }) => {
                console.log("Respuesta Api ", data)
                store.dispatch('storeOrder', data)
            })
            .catch(error => {
                errorReporting.showErrorResponseFromAPI(error)
            })
    },

    saveOrderData(orderData){
        return apiClient
            .post('/order/update',orderData)
            .then(({ data }) => {
                console.log("Respuesta Api ", data)
                let notification = [
                    {
                        'message': 'Pedido actualizado correctamente',
                        'type': "success"
                    }
                ]

                store.dispatch('sendNotification',  notification)
                store.dispatch('removeNotificationFromTimeout')
                store.dispatch('clearErrors')
            })
            .catch(error => {
                store.dispatch('storeOrderFormErrors', error.response.data.errors)
            })
    },

    addOrder(orderData){
        return apiClient
            .post('/order/add',orderData)
            .then(({ data }) => {
                let notification = [
                    {
                        'message': 'Pedido creado correctamente',
                        'type': "success"
                    }
                ]
                store.dispatch('sendNotification', notification);

                const orderId = data.data.id;
                router.push(`/orders/edit/${orderId}`);
                store.dispatch('removeNotificationFromTimeout');
                store.dispatch('clearErrors')

            })
            .catch(error => {
                store.dispatch('storeOrderFormErrors', error.response.data.errors)
            })
    },

}

