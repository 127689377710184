<template>
  <teleport to="body">
    <Loader v-if="provider.showLoader.value" />
  </teleport>
  <header class="bg-white shadow-sm">
    <div class="flex max-w-full mx-auto py-4 px-4 sm:px-6 lg:px-8">
      <h1 class="text-lg leading-6 font-semibold text-gray-900">
        Gestión de Proveedores
      </h1>
    </div>
  </header>

  <div class="container m-auto grid grid-cols-3 gap-4 py-4">
    <div class="col-span-3 md:col-span-3 lg:col-span-2">
      <form @submit.prevent="save">
        <div class="overflow-hidden bg-white shadow sm:rounded-lg">
          <div class="container m-auto grid grid-cols-3 gap-4 px-4 py-5 sm:px-6">
            <div class="col-span-2">
              <h3 class="text-base font-semibold leading-6 text-gray-900">Datos Proveedores</h3>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">Información y detalles</p>
            </div>

            <div class="col-span-1 justify-self-end">
              <button type="submit"
                      class="h-50 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                Guardar
              </button>
            </div>
          </div>

          <div class="border-t border-gray-200">
            <dl>
              <div class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500 py-3">CIF</dt>
                <input required
                    class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                    type="text" v-model="provider.providerData.value.cif" />
                <dt v-if="provider.providerFormErrors.value.cif" class="text-sm font-medium text-gray-500 py-3 text-red-600">
                  {{ provider.providerFormErrors.value.cif[0] }}
                </dt>
              </div>
              <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500 py-3">Nombre</dt>
                <input required
                    class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                    type="text" v-model="provider.providerData.value.nombre" />
                <dt v-if="provider.providerFormErrors.value.nombre" class="text-sm font-medium text-gray-500 py-3 text-red-600">
                  {{ provider.providerFormErrors.value.nombre[0] }}
                </dt>
              </div>
              <div class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500 py-3">Domicilio</dt>
                <input required
                    class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                    type="text" v-model="provider.providerData.value.direccion" />
                <dt v-if="provider.providerFormErrors.value.direccion" class="text-sm font-medium text-gray-500 py-3 text-red-600">
                  {{ provider.providerFormErrors.value.direccion[0] }}
                </dt>
              </div>
              <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500 py-3">C.P.</dt>
                <input required
                    class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                    type="text" v-model="provider.providerData.value.cp" />
                <dt v-if="provider.providerFormErrors.value.cp" class="text-sm font-medium text-gray-500 py-3 text-red-600">
                  {{ provider.providerFormErrors.value.cp[0] }}
                </dt>
              </div>
              <div class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500 py-3">Provincia</dt>
                <input required
                    class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                     type="text" v-model="provider.providerData.value.provincia" />
              </div>
              <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500 py-3">Población</dt>
                <input required
                    class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                    type="text" v-model="provider.providerData.value.poblacion" />
                <dt v-if="provider.providerFormErrors.value.poblacion" class="text-sm font-medium text-gray-500 py-3 text-red-600">
                  {{ provider.providerFormErrors.value.poblacion[0] }}
                </dt>
              </div>
              <div class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500 py-3">Teléfono</dt>
                <input required
                    class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                    type="text" v-model="provider.providerData.value.telefono" />
                <dt v-if="provider.providerFormErrors.value.telefono" class="text-sm font-medium text-gray-500 py-3 text-red-600">
                  {{ provider.providerFormErrors.value.telefono[0] }}
                </dt>
              </div>
              <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500 py-3">Email</dt>
                <input required
                    class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                    type="email" v-model="provider.providerData.value.email" />
                <dt v-if="provider.providerFormErrors.value.email" class="text-sm font-medium text-gray-500 py-3 text-red-600">
                  {{ provider.providerFormErrors.value.email[0] }}
                </dt>
              </div>
              <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500 py-3">Contacto</dt>
                <input required
                       class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                       type="text" v-model="provider.providerData.value.contacto" />
                <dt v-if="provider.providerFormErrors.value.contacto" class="text-sm font-medium text-gray-500 py-3 text-red-600">
                  {{ provider.providerFormErrors.value.contacto[0] }}
                </dt>
              </div>
            </dl>
          </div>
        </div>
      </form>
    </div>
    <div class="col-span-3 md:col-span-3 lg:col-span-3">
      <div class="overflow-hidden bg-white shadow sm:rounded-lg">
        <div class="container m-auto grid grid-cols-3 gap-4 px-4 py-5 sm:px-6">
          <div class="col-span-2">
            <h3 class="text-base font-semibold leading-6 text-gray-900">Compras</h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">Listado de compras</p>
          </div>

          <div class="col-span-1 justify-self-end">
            <button type="submit" @click="openNewOrder"
                    class="h-50 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
              Nueva compra
            </button>
          </div>
        </div>

        <div v-if="provider.providerData.value.compras" class="border-t border-gray-200">
          <ul role="list" class="divide-y divide-gray-200">
            <li class="hidden md:block bg-gray-600 text-white">
              <div class="px-4 py-4 sm:px-6">
                <div class="sm:flex w-full sm:justify-between">
                  <div class="flex w-full items-center text-center">
                    <p class="w-1/3 text-left font-bold">Fecha compra</p>
                    <p class="w-1/3 text-left font-bold">Descripción producto</p>
                    <p class="w-1/3 text-left font-bold">Cantidad</p>
                    <p class="w-1/3 text-left font-bold">Precio unidad</p>
                    <p class="w-1/3 text-left font-bold">Total</p>
                    <p class="w-1/12 text-center font-bold">Acciones</p>
                  </div>
                </div>
              </div>
            </li>
            <li v-for="(compra,index) in provider.providerData.value.compras" :key="index" class="text-gray-500 text-sm">
              <div class="px-4 py-4 sm:px-6">
                <div class="sm:flex w-full sm:justify-between">
                  <div class="md:flex w-full items-center">
                    <p class="w-full mb-2 md:mb-0 md:w-1/3 text-left">
                      {{ getDateInFormat(compra.created_at) }}
                    </p>
                    <p class="w-full mb-2 md:mb-0 md:w-2/3 text-left">
                      {{ compra.descripcion_producto }}
                    </p>
                    <p class="w-full mb-2 md:mb-0 md:w-1/3 text-left">
                      {{ compra.cantidad }}
                    </p>
                    <p class="w-full mb-2 md:mb-0 md:w-1/3 text-left">
                      {{ compra.precio_unitario }} €
                    </p>
                    <p class="w-full mb-2 md:mb-0 md:w-1/3 text-left">
                      {{ compra.total }} €
                    </p>
                    <p class="flex w-full mb-2 md:mb-0 md:w-1/12 text-center justify-center">
                      <button title="Editar venta"  class="flex w-1/2 justify-center" @click="editOrder(compra)">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                        </svg>
                      </button>
                      <button title="Eliminar venta"  class="flex w-1/2 justify-center" @click="deleteOrder(compra.id)">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                        </svg>
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
   </div>

  <TransitionRoot as="template" :show="showNewOrder">
    <Dialog as="div" class="fixed inset-0 overflow-hidden" @close="showNewOrder = false">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />
        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
                           enter-from="translate-x-full" enter-to="translate-x-0"
                           leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0"
                           leave-to="translate-x-full">
            <div class="bg-gray-50 w-screen max-w-2xl shadow-2xl overflow-auto">
              <div class="h-screen bg-gray-50 px-4 py-5">
                <div class="px-4 py-6">
                  <div class="flex items-start justify-between space-x-3">
                    <div class="space-y-1">
                      <DialogTitle class="text-lg font-medium text-gray-900">
                        Nueva compra
                      </DialogTitle>
                    </div>
                    <div class="h-7 flex items-center">
                      <button type="button" class="text-gray-400 hover:text-gray-700" @click="showNewOrder = false">
                        <span class="sr-only">Cerrar</span>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="flex w-full">
                  <div class="px-4 py-3 w-1/3 text-sm font-medium text-gray-700">
                    Descripción producto
                  </div>
                  <div class="px-4 pt-1 w-2/3 text-sm font-medium text-gray-700">
                    <input required
                           class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                           type="text" v-model="productDescription" />
                  </div>
                </div>
                <div class="flex w-full">
                  <div class="px-4 py-3 w-1/3 text-sm font-medium text-gray-700">
                    Cantidad
                  </div>
                  <div class="px-4 pt-1 w-2/3 text-sm font-medium text-gray-700">
                    <input required
                           class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                           type="text" v-model="quantity" />
                  </div>
                </div>
                <div class="flex w-full">
                  <div class="px-4 py-3 w-1/3 text-sm font-medium text-gray-700">
                    Importe total
                  </div>
                  <div class="px-4 pt-1 w-2/3 text-sm font-medium text-gray-700">
                    <input required
                           class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                           type="text" v-model="amount" />
                  </div>
                </div>
                <div class="pt-5 px-4 overflow-auto justify-self-end">
                  <button @click="newOrder"
                          class="h-50 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                    Nueva compra
                  </button>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <TransitionRoot as="template" :show="showEditOrder">
    <Dialog as="div" class="fixed inset-0 overflow-hidden" @close="showEditOrder = false">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />
        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
                           enter-from="translate-x-full" enter-to="translate-x-0"
                           leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0"
                           leave-to="translate-x-full">
            <div class="bg-gray-50 w-screen max-w-2xl shadow-2xl overflow-auto">
              <div class="h-screen bg-gray-50 px-4 py-5">
                <div class="px-4 py-6">
                  <div class="flex items-start justify-between space-x-3">
                    <div class="space-y-1">
                      <DialogTitle class="text-lg font-medium text-gray-900">
                        Editar compra
                      </DialogTitle>
                    </div>
                    <div class="h-7 flex items-center">
                      <button type="button" class="text-gray-400 hover:text-gray-700" @click="showEditOrder = false">
                        <span class="sr-only">Cerrar</span>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="flex w-full">
                  <div class="px-4 py-3 w-1/3 text-sm font-medium text-gray-700">
                    Descripción del producto
                  </div>
                  <div class="px-4 pt-1 w-2/3 text-sm font-medium text-gray-700">
                    <input required
                           class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                           type="text" v-model="productDescription" />
                  </div>
                </div>
                <div class="flex w-full">
                  <div class="px-4 py-3 w-1/3 text-sm font-medium text-gray-700">
                    Cantidad
                  </div>
                  <div class="px-4 pt-1 w-2/3 text-sm font-medium text-gray-700">
                    <input required
                           class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                           type="text" v-model="quantity" />
                  </div>
                </div>
                <div class="flex w-full">
                  <div class="px-4 py-3 w-1/3 text-sm font-medium text-gray-700">
                    Importe total
                  </div>
                  <div class="px-4 pt-1 w-2/3 text-sm font-medium text-gray-700">
                    <input required disabled
                           class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                           type="text" v-model="amount" />
                  </div>
                </div>
                <div class="pt-5 px-4 overflow-auto justify-self-end">
                  <button @click="updateOrder"
                          class="h-50 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                    Guardar
                  </button>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
 </template>

 <script>
 import { useProvider } from "@/use/provider";

 import {defineAsyncComponent, reactive, ref} from "vue";

 const Loader = defineAsyncComponent(() => import("@/components/Common/Loader/Loader.vue"))
 import CardWithHeaderComponent from "@/components/Layout/Panels/CardWithHeaderComponent";
 import ToggleComponent from "../../components/Common/Form/ToggleComponent";
 import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
 import Pagination from "../../components/Pagination";
 import Notification from "@/App.vue";
 import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
 import CustomSelectInput from "../../components/Common/Select/CustomSelectInput";
 import moment from "moment";
 import ConfirmationAlertDelete from '../../components/Common/Confirmation/ConfirmationAlertDelete.vue';

 import {useUser} from "../../use/user";


 export default {
   name: "providerManagement",
   components: {
     Notification,
     Pagination,
     Loader,
     CardWithHeaderComponent,
     ToggleComponent,
     Dialog,
     DialogOverlay,
     DialogTitle,
     TransitionChild,
     TransitionRoot,
     CustomSelectInput,
     Listbox, ListboxButton, ListboxOption, ListboxOptions,
     ConfirmationAlertDelete

   },
   setup(props, context) {
     const alert = reactive({
       show: false,
     });

     return {
       user: useUser(),
       provider: useProvider(),
       alert,
     }
   },
   data() {
     return {
       formatter: new Intl.NumberFormat('es-ES', {
         style: 'currency',
         currency: 'EUR'
       }),
       showNewOrder: false,
       productDescription: '',
       quantity: 0,
       amount: 0,
       showEditOrder: false,
       orderId: '' ,
     }
   },
   methods: {
     save() {
       this.provider.saveProviderData()
     },

     getDateInFormat(date) {
       return moment(date).format('DD/MM/YYYY HH:mm');
     },

     showIfHavePermissions(permission) {
       return this.user.permissions.value ? !!this.user.permissions.value.filter(e => e.name === permission).length : false
     },

     openNewOrder() {
       this.showNewOrder = true
       this.productDescription = ''
       this.quantity = 0
       this.amount = 0
     },

     newOrder(){
       this.provider.addNewOrder(
           {
             proveedorId: this.provider.providerData.value.id,
             descripcionProducto: this.productDescription,
             cantidad: this.quantity,
             precioUnitario: this.amount / this.quantity,
             total: this.amount,
           }
       )
     },

     editOrder(order){
       this.showEditSale = true
       this.orderId = order.id
       this.productDescription = order.descripcion_producto
       this.quantity = order.cantidad
       this.amount = order.total
     },

     updateOrder(){
       this.provider.updateOrder(
           {
             proveedorId: this.provider.providerData.value.id,
             id: this.orderId,
             descripcionProducto: this.productDescription,
             cantidad: this.quantity,
             precioUnitario: this.amount / this.quantity,
             total: this.amount,
           }
       )
     },

     deleteOrder(id) {
       this.provider.deleteOrder({
         id: id,
         proveedorId: this.provider.providerData.value.id,
       })
     },
   },
   async created() {
     await this.provider.getProviderData(this.$route.params.id)
   },
   mounted() {
   },


 }
 </script>