import { reactive, computed, watch  } from 'vue'
import { useRouter } from 'vue-router'
import store from '@/store/index.js'


export function useOrder() {
    const router = useRouter()

    const orderData = computed(
        () => store.getters.getOrderData
    )

    const orderFormErrors = computed(
        () => store.getters.getOrderFormErrors
    )

    const showLoader = reactive({
        value: false
    })

    async function getOrderData(code) {
        this.showLoader.value = true
        await store
            .dispatch('getOrderData', code)
            .then(
                () => {
                    this.showLoader.value = false
                }
            )
    }

    function saveOrderData() {
        this.showLoader.value = true
        store
            .dispatch('saveOrderData', this.orderData.value)
            .then(
                () => {
                    this.showLoader.value = false
                }
            )
    }

    function addOrderData() {
        this.showLoader.value = true
        console.log("DATOS A ENVIAR",this.orderData.value);
        store
            .dispatch('addOrderData', this.orderData.value)
            .then(
                () => {
                    this.showLoader.value = false

                }
            )
    }

    return {
        orderData,
        showLoader,
        getOrderData,
        saveOrderData,
        addOrderData,
        orderFormErrors,
    }
}