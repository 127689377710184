import OrdersManagementService from "../../../services/Orders/OrdersManagementService";

export default {
    state: {
        data: {
            orders: [],
            numberOfOrders: 0,
            numberOfPages: 0,
            ordersPerPages: 0
        },
    },
    mutations: {
        SET_ORDERS_DATA (state, ordersData) {
            state.data = ordersData
        },
    },
    actions: {
        index({ commit }){
            return OrdersManagementService.index()
        },
        getOrdersByFilters({ commit }, filters){
            return  OrdersManagementService.index(filters)
        },
        storeOrders({ commit }, OrdersObject){
            commit('SET_ORDERS_DATA', OrdersObject)
        }
    },
    getters: {
        getOrdersData(state){
            console.log('ORDERS GETTER',state.data)

            return state.data
        },
    }
};