import customAxios from '@/axios/index'
import store from '@/store'
import errorReporting from '@/services/ErrorReporting.js'
import router from '@/router';

const apiClient = customAxios


export default {
    index(filters){
        return apiClient
            .post('/sales',filters)
            .then(({ data }) => {
                console.log("Respuesta Api ", data)
                store.dispatch('storeSales', data)
            })
            .catch(error => {
                errorReporting.showErrorResponseFromAPI(error)
            })
    },

    getSaleData(code){
        let path = '/sale/' + code

        return apiClient
            .get(path)
            .then(({ data }) => {
                console.log("Respuesta Api ", data)
                store.dispatch('storeSale', data)
            })
            .catch(error => {
                errorReporting.showErrorResponseFromAPI(error)
            })
    },

    saveSaleData(saleData){
        return apiClient
            .post('/sale/update',saleData)
            .then(({ data }) => {
                console.log("Respuesta Api ", data)
                let notification = [
                    {
                        'message': 'Venta actualizada correctamente',
                        'type': "success"
                    }
                ]

                store.dispatch('sendNotification',  notification)
                store.dispatch('removeNotificationFromTimeout')
                store.dispatch('clearErrors')
            })
            .catch(error => {
                store.dispatch('storeSaleFormErrors', error.response.data.errors)
            })
    },

    addSale(saleData){
        return apiClient
            .post('/sale/add',saleData)
            .then(({ data }) => {
                let notification = [
                    {
                        'message': 'Venta creada correctamente',
                        'type': "success"
                    }
                ]
                store.dispatch('sendNotification', notification);

                const saleId = data.data.id;
                router.push(`/sales/edit/${saleId}`);
                store.dispatch('removeNotificationFromTimeout');
                store.dispatch('clearErrors')

            })
            .catch(error => {
                store.dispatch('storeSaleFormErrors', error.response.data.errors)
            })
    },

}

