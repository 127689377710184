<template>
  <teleport to="body">
    <Loader v-if="commercial.showLoader.value" />
  </teleport>
  <header class="bg-white shadow-sm">
    <div class="flex max-w-full mx-auto py-4 px-4 sm:px-6 lg:px-8">
      <h1 class="text-lg leading-6 font-semibold text-gray-900">
        Gestión de Comerciales
      </h1>
    </div>
  </header>


  <div class="container m-auto grid grid-cols-3 gap-4 py-4">
    <div class="col-span-3 md:col-span-3 lg:col-span-2">
      <form @submit.prevent="commercial.addCommercialData()">
        <div class="overflow-hidden bg-white shadow sm:rounded-lg">
          <div class="container m-auto grid grid-cols-3 gap-4 px-4 py-5 sm:px-6">
            <div class="col-span-2">
              <h3 class="text-base font-semibold leading-6 text-gray-900">Datos Comercial</h3>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">Información y detalles</p>
            </div>

            <div class="col-span-1 justify-self-end">
              <button type="submit" class="h-50 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                Crear
              </button>
            </div>
          </div>

          <div class="border-t border-gray-200">
            <dl>

              <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500 py-3">Nombre</dt>
                <input
                    class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                    type="text" v-model="commercial.commercialData.value.nombre" />
                <dt v-if="commercial.commercialFormErrors.value.nombre" class="text-sm font-medium text-gray-500 py-3 text-red-600">
                  {{ commercial.commercialFormErrors.value.nombre[0] }}
                </dt>
              </div>
              <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500 py-3">Población</dt>
                <input
                    class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                    type="text" v-model="commercial.commercialData.value.poblacion" />
                <dt v-if="commercial.commercialFormErrors.value.poblacion" class="text-sm font-medium text-gray-500 py-3 text-red-600">
                  {{ commercial.commercialFormErrors.value.poblacion[0] }}
                </dt>
              </div>
              <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500 py-3">Provincia</dt>
                <input
                    class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                    type="text" v-model="commercial.commercialData.value.provincia" />
                <dt v-if="commercial.commercialFormErrors.value.provincia" class="text-sm font-medium text-gray-500 py-3 text-red-600">
                  {{ commercial.commercialFormErrors.value.provincia[0] }}
                </dt>
              </div>
              <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500 py-3">Teléfono</dt>
                <input
                    class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                    type="text" v-model="commercial.commercialData.value.telefono" />
                <dt v-if="commercial.commercialFormErrors.value.telefono" class="text-sm font-medium text-gray-500 py-3 text-red-600">
                  {{ commercial.commercialFormErrors.value.telefono[0] }}
                </dt>
              </div>

              <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500 py-3">Email</dt>
                <input
                    class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                    type="text" v-model="commercial.commercialData.value.email" />
                <dt v-if="commercial.commercialFormErrors.value.email" class="text-sm font-medium text-gray-500 py-3 text-red-600">
                  {{ commercial.commercialFormErrors.value.email[0] }}
                </dt>
              </div>
            </dl>
          </div>
        </div>
      </form>
    </div>

    <div class="hidden col-span-3 md:col-span-3 lg:col-span-1">
      <div class=" bg-white shadow sm:rounded-lg">
        <div class="container m-auto grid grid-cols-3 gap-4 px-4 py-5 sm:px-6">
          <div class="col-span-1 justify-self-end">
            <button type="submit" @click="commercial.addCommercialData()"
                    class="h-50 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
              Crear
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>


</template>

<script>
import { useCommercial } from "@/use/commercial";
import { defineAsyncComponent, ref } from "vue";

const Loader = defineAsyncComponent(() => import("@/components/Common/Loader/Loader.vue"))
import CardWithHeaderComponent from "@/components/Layout/Panels/CardWithHeaderComponent";
import ToggleComponent from "../../components/Common/Form/ToggleComponent";
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import Pagination from "../../components/Pagination";
import Notification from "@/App.vue";
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
//import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/24/solid'
import CustomSelectInput from "../../components/Common/Select/CustomSelectInput";
import moment from "moment";

export default {
  name: "commercialAdd",
  components: {
    Notification,
    Pagination,
    Loader,
    CardWithHeaderComponent,
    ToggleComponent,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    CustomSelectInput,
    Listbox, ListboxButton, ListboxOption, ListboxOptions,

  },
  setup(props, context) {
    const commercial = useCommercial();
    const selectedTab = ref('edit');


    return {
      commercial,
      selectedTab,
    }
  },
  data() {
    return {
      formatter: new Intl.NumberFormat('es-ES', {
        style: 'currency',
        currency: 'EUR'
      }),
    }
  },
  methods: {
    save() {
      this.commercial.saveCommercialData()
    },

    getDateInFormat(date) {
      return moment(date).format('DD/MM/YYYY HH:mm');
    },
  },
  async created() {
    this.commercial.clearCommercialData()
  },
  mounted() {
  },
}
</script>

<style scoped></style>