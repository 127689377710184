<template>
  <teleport to="body">
    <Loader v-if="showLoader.value"/>
  </teleport>
  <header class="bg-white shadow-sm">
    <div class="flex max-w-full mx-auto py-4 px-4 sm:px-6 lg:px-8">
      <div class="w-1/2">
        <h1 class="text-lg leading-6 font-semibold text-gray-900">
          Gestión de Clientes
        </h1>
      </div>
      <div  class="w-1/2 justify-center text-right">
        <router-link
            to="/clients/add"
                class="justify-items-end h-50 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
               class="w-6 h-6 inline-block">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          Añadir Cliente
        </router-link>
      </div>

    </div>
  </header>
  <div class="max-w-full mx-auto py-6 sm:px-6 lg:px-8 grid gap-5 grid-cols-1 md:grid-cols-6">
    <div class="w-full md:col-span-2">
      <label class="text-gray-500">Nombre empresa</label>
      <input
        class="flex w-full px-4 py-2 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
        id="autoComplete" type="search" dir="ltr" spellcheck=false autocorrect="off" autocomplete="off"
        autocapitalize="off" v-model="filters.nombre">
    </div>
    <div class="w-full md:col-span-2">
      <label class="text-gray-500">Localidad</label>
      <input type="text" @keyup.enter="search" class="flex w-full px-4 py-2 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline" v-model="filters.poblacion">
    </div>
    <div class="w-full">
      <label class="text-gray-500">Teléfono</label>
      <input type="text" @keyup.enter="search" class="flex w-full px-4 py-2 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline" v-model="filters.telefono">
    </div>
    <div class="w-full ">
      <label class="text-gray-500">CIF/NIF/NIE</label>
      <input type="text" @keyup.enter="search" class="flex w-full px-4 py-2 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline" v-model="filters.dni">
    </div>
    <div class="w-full md:col-span-2">
      <label class="text-gray-500">Email</label>
      <input type="text" @keyup.enter="search" class="flex w-full px-4 py-2 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline" v-model="filters.email">
    </div>
    <div class="w-full md:col-span-2">
      <label class="text-gray-500">Nombre contacto</label>
      <input type="text" @keyup.enter="search" class="flex w-full px-4 py-2 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline" v-model="filters.contacto">
    </div>
    <div class="w-full flex items-end ">
      <button @click="search" class="w-content h-content bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        Buscar
      </button>
    </div>
  </div>

  <clients-management-result v-show="clientsData.numberOfClients > 0"></clients-management-result>

  <pagination :currentpage="filters.page" :numberofpages="clientsData.numberOfPages" :numberofelements="clientsData.numberOfClients"></pagination>
</template>

<script>
import {useClients} from "@/use/clients";
import {useClient} from "@/use/client";

import {defineAsyncComponent} from "vue";
import moment from "moment";
const Loader = defineAsyncComponent(()=>import("@/components/Common/Loader/Loader.vue"))
import ClientsManagementResult from "../../components/Clients/ClientsManagementResult";
import Pagination from "../../components/Pagination";
import '/src/assets/autocomplete.css';
import {useUser} from "../../use/user";


export default {
  name: "clientsManagement",
  components: {
    Loader,
    ClientsManagementResult,
    Pagination
  },
  setup(props, context) {
    const client = useClient();
    const user = useUser();

    return {
      ...useClients(),client,user
    }
  },
  data() {
    return {
      formatter: new Intl.NumberFormat('es-ES', {
        style: 'currency',
        currency: 'EUR'
      })
    }
  },
  methods: {
    search(){
      this.searchClientsByFilters()
    },
    getDateInFormat(date){
      return moment(date).format('YYYY/MM/DD')
    },
    goToPage(page){
      this.searchClientsByFilters(page)
    },
    showIfHavePermissions(permission) {
      return this.user.permissions.value ? !!this.user.permissions.value.filter(e => e.name === permission).length : false
    }
  },
  mounted() {
  },
}
</script>