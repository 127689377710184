import CommercialsManagementService from "../../../services/Commercials/CommercialsManagementService";

export default {
    state: {
        data: {
            commercials: [],
            numberOfCommercials: 0,
            numberOfPages: 0,
            commercialsPerPages: 0
        },
    },
    mutations: {
        SET_COMMERCIALS_DATA (state, CommercialsData) {
            state.data = CommercialsData
        },
    },
    actions: {
        index({ commit }){
            return CommercialsManagementService.index()
        },
        getCommercialsByFilters({ commit }, filters){
            return  CommercialsManagementService.index(filters)
        },
        storeCommercials({ commit }, CommercialsObject){
            commit('SET_COMMERCIALS_DATA', CommercialsObject)
        }
    },
    getters: {
        getCommercialsData(state){
            console.log('COMMERCIALS GETTER',state.data)

            return state.data
        },
    }
};