<template>
  <teleport to="body">
    <Loader v-if="client.showLoader.value" />
  </teleport>
  <header class="bg-white shadow-sm">
    <div class="flex max-w-full mx-auto py-4 px-4 sm:px-6 lg:px-8">
      <h1 class="text-lg leading-6 font-semibold text-gray-900">
        Gestión de Clientes
      </h1>
    </div>
  </header>

  <div class="container m-auto grid grid-cols-3 gap-4 py-4">
    <div class="col-span-3 md:col-span-3 lg:col-span-2">
      <form @submit.prevent="save">
      <div class="overflow-hidden bg-white shadow sm:rounded-lg">
        <div class="container m-auto grid grid-cols-3 gap-4 px-4 py-5 sm:px-6">
          <div class="col-span-2">
            <h3 class="text-base font-semibold leading-6 text-gray-900">Datos Cliente</h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">Información y detalles</p>
          </div>

          <div class="col-span-1 justify-self-end">
            <button type="submit"
                    class="h-50 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
              Guardar
            </button>
          </div>
        </div>

        <div class="border-t border-gray-200">
          <dl>
            <div class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500 py-3">CIF/DNI/NIE</dt>
              <input required
                  class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                  type="text" v-model="client.clientData.value.dni" />
              <dt v-if="client.clientFormErrors.value.dni" class="text-sm font-medium text-gray-500 py-3 text-red-600">
                {{ client.clientFormErrors.value.dni[0] }}
              </dt>
            </div>
            <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500 py-3">Nombre empresa</dt>
              <input required
                  class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                  type="text" v-model="client.clientData.value.nombre" />
              <dt v-if="client.clientFormErrors.value.nombre" class="text-sm font-medium text-gray-500 py-3 text-red-600">
                {{ client.clientFormErrors.value.nombre[0] }}
              </dt>
            </div>
            <div class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500 py-3">Domicilio</dt>
              <input required
                  class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                  type="text" v-model="client.clientData.value.direccion" />
              <dt v-if="client.clientFormErrors.value.direccion" class="text-sm font-medium text-gray-500 py-3 text-red-600">
                {{ client.clientFormErrors.value.direccion[0] }}
              </dt>
            </div>
            <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500 py-3">C.P.</dt>
              <input required
                  class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                  type="text" v-model="client.clientData.value.cp" />
              <dt v-if="client.clientFormErrors.value.cp" class="text-sm font-medium text-gray-500 py-3 text-red-600">
                {{ client.clientFormErrors.value.cp[0] }}
              </dt>
            </div>
            <div class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500 py-3">Provincia</dt>
              <input required
                  class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                   type="text" v-model="client.clientData.value.provincia" />
            </div>
            <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500 py-3">Población</dt>
              <input required
                  class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                  type="text" v-model="client.clientData.value.poblacion" />
              <dt v-if="client.clientFormErrors.value.poblacion" class="text-sm font-medium text-gray-500 py-3 text-red-600">
                {{ client.clientFormErrors.value.poblacion[0] }}
              </dt>
            </div>
            <div class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500 py-3">Teléfono</dt>
              <input required
                  class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                  type="text" v-model="client.clientData.value.telefono" />
              <dt v-if="client.clientFormErrors.value.telefono" class="text-sm font-medium text-gray-500 py-3 text-red-600">
                {{ client.clientFormErrors.value.telefono[0] }}
              </dt>
            </div>
            <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500 py-3">Email</dt>
              <input
                  class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                  type="email" v-model="client.clientData.value.email" />
              <dt v-if="client.clientFormErrors.value.email" class="text-sm font-medium text-gray-500 py-3 text-red-600">
                {{ client.clientFormErrors.value.email[0] }}
              </dt>
            </div>
            <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500 py-3">Contacto</dt>
              <input required
                     class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                     type="text" v-model="client.clientData.value.contacto" />
              <dt v-if="client.clientFormErrors.value.contacto" class="text-sm font-medium text-gray-500 py-3 text-red-600">
                {{ client.clientFormErrors.value.contacto[0] }}
              </dt>
            </div>
          </dl>
        </div>
      </div>
      </form>
    </div>
    <div class="col-span-1 md:col-span-1 lg:col-span-1">
      <div class="overflow-hidden bg-white shadow sm:rounded-lg">
        <div class="container m-auto grid grid-cols-3 gap-4 px-4 py-5 sm:px-6">
          <div class="col-span-2">
            <h3 class="text-base font-semibold leading-6 text-gray-900">Datos comercial</h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">Información del comercial</p>
          </div>

          <div class="col-span-1 justify-self-end">
            <button type="submit" @click="showCommercial = true"
                    class="h-50 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
              Seleccionar
            </button>
          </div>
        </div>

        <div v-if="client.clientData.value.comercial" class="border-t border-gray-200">
          <dl>
            <div class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              {{ client.clientData.value.comercial.nombre }}
            </div>
            <div class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              {{ client.clientData.value.comercial.telefono }}
            </div>
          </dl>
        </div>
      </div>
    </div>
    <div class="col-span-3 md:col-span-3 lg:col-span-3">
      <div class="overflow-hidden bg-white shadow sm:rounded-lg">
        <div class="container m-auto grid grid-cols-3 gap-4 px-4 py-5 sm:px-6">
          <div class="col-span-2">
            <h3 class="text-base font-semibold leading-6 text-gray-900">Ventas</h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">Listado de ventas</p>
          </div>

          <div class="col-span-1 justify-self-end">
            <button type="submit" @click="openNewSale"
                    class="h-50 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
              Nueva venta
            </button>
          </div>
        </div>

        <div v-if="client.clientData.value.ventas" class="border-t border-gray-200">
          <ul role="list" class="divide-y divide-gray-200">
            <li class="hidden md:block bg-gray-600 text-white">
              <div class="px-4 py-4 sm:px-6">
                <div class="sm:flex w-full sm:justify-between">
                  <div class="flex w-full items-center text-center">
                    <p class="w-1/3 text-left font-bold">Fecha venta</p>
                    <p class="w-1/3 text-left font-bold">Primer nº sorteo</p>
                    <p class="w-1/3 text-left font-bold">Último nº sorteo</p>
                    <p class="w-1/3 text-left font-bold">Cantidad</p>
                    <p class="w-1/3 text-left font-bold">Precio unidad</p>
                    <p class="w-1/3 text-left font-bold">Total</p>
                    <p class="w-1/12 text-center font-bold">Acciones</p>
                  </div>
                </div>
              </div>
            </li>
            <li v-for="(venta,index) in client.clientData.value.ventas" :key="index" class="text-gray-500 text-sm">
            <div class="px-4 py-4 sm:px-6">
              <div class="sm:flex w-full sm:justify-between">
                <div class="md:flex w-full items-center">
                  <p class="w-full mb-2 md:mb-0 md:w-1/3 text-left">
                    {{ getDateInFormat(venta.created_at) }}
                  </p>
                  <p class="w-full mb-2 md:mb-0 md:w-1/3 text-left">
                    {{ venta.primer_numero_sorteo }}
                  </p>
                  <p class="w-full mb-2 md:mb-0 md:w-1/3 text-left">
                    {{ venta.ultimo_numero_sorteo }}
                  </p>
                  <p class="w-full mb-2 md:mb-0 md:w-1/3 text-left">
                    {{ venta.cantidad }}
                  </p>
                  <p class="w-full mb-2 md:mb-0 md:w-1/3 text-left">
                    {{ venta.precio_unitario }} €
                  </p>
                  <p class="w-full mb-2 md:mb-0 md:w-1/3 text-left">
                    {{ venta.total }} €
                  </p>
                  <p class="flex w-full mb-2 md:mb-0 md:w-1/12 text-center justify-center">
                    <button @click="client.downloadInvoice(venta.id)" class="ml-2 inline-block"
                            title="Descargar factura PDF">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                      </svg>
                    </button>
                    <button title="Editar venta"  class="flex w-1/2 justify-center" @click="editSale(venta)">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                      </svg>
                    </button>
                    <button title="Eliminar venta"  class="flex w-1/2 justify-center" @click="deleteSale(venta.id)">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                      </svg>
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </li>
          </ul>
        </div>
      </div>
    </div>
   </div>

  <TransitionRoot as="template" :show="showCommercial">
    <Dialog as="div" class="fixed inset-0 overflow-hidden" @close="showCommercial = false">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />
        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
                           enter-from="translate-x-full" enter-to="translate-x-0"
                           leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0"
                           leave-to="translate-x-full">
            <div class="bg-gray-50 w-screen max-w-2xl shadow-2xl overflow-auto">
              <div class="h-screen bg-gray-50 px-4 py-5">
                <div class="px-4 py-6">
                  <div class="flex items-start justify-between space-x-3">
                    <div class="space-y-1">
                      <DialogTitle class="text-lg font-medium text-gray-900">
                        Añadir comercial
                      </DialogTitle>
                    </div>
                    <div class="h-7 flex items-center">
                      <button type="button" class="text-gray-400 hover:text-gray-700" @click="showCommercial = false">
                        <span class="sr-only">Cerrar</span>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="px-4 pt-1  text-sm font-medium text-gray-700">
                  <input required
                         class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                         type="text" v-model="commercial" />
                </div>
                <div class="px-4 pt-1  text-sm font-medium text-gray-700">
                  <button type="submit" @click="getComercial"
                          class="h-50 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                    Buscar
                  </button>
                </div>

                <div class="pt-5 px-4 overflow-auto">
                  <div class="flex space-y-1">
                    <DialogTitle class="text-lg font-medium text-gray-900">
                      Comerciales
                    </DialogTitle>
                  </div>
                  <div v-for="(commercial, index) in commercials.commercialsData.value.commercials" :key="index"
                       class="flex-col w-full py-4 px-4 mt-3 bg-white border-b-2 border-r-2 border-gray-200 sm:px-4 sm:py-4 md:px-4 sm:rounded-lg sm:shadow-sm md:w-full">
                    <div class="flex flex-row md-10 cursor-pointer" @click="updateCommercialToClient(commercial.id)">
                      <div class="flex-col mt-1">
                        <div class="flex items-center flex-1 px-4 font-bold leading-tight">
                          {{ commercial.nombre }}
                        </div>
                        <div class="flex items-center flex-1 px-4 font-bold leading-tight">
                          {{ commercial.telefono }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <TransitionRoot as="template" :show="showNewSale">
    <Dialog as="div" class="fixed inset-0 overflow-hidden" @close="showNewSale = false">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />
        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
                           enter-from="translate-x-full" enter-to="translate-x-0"
                           leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0"
                           leave-to="translate-x-full">
            <div class="bg-gray-50 w-screen max-w-2xl shadow-2xl overflow-auto">
              <div class="h-screen bg-gray-50 px-4 py-5">
                <div class="px-4 py-6">
                  <div class="flex items-start justify-between space-x-3">
                    <div class="space-y-1">
                      <DialogTitle class="text-lg font-medium text-gray-900">
                        Nueva venta
                      </DialogTitle>
                    </div>
                    <div class="h-7 flex items-center">
                      <button type="button" class="text-gray-400 hover:text-gray-700" @click="showNewSale = false">
                        <span class="sr-only">Cerrar</span>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="flex w-full">
                  <div class="px-4 py-3 w-1/3 text-sm font-medium text-gray-700">
                    Numero de paquetes
                  </div>
                  <div class="px-4 pt-1 w-2/3 text-sm font-medium text-gray-700">
                    <input required
                           @change="updateNewSaleTotal"
                           class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                           type="text" v-model="numberOfPackages" />
                  </div>
                </div>
                <div class="flex w-full">
                  <div class="px-4 py-3 w-1/3 text-sm font-medium text-gray-700">
                    Importe
                  </div>
                  <div class="px-4 pt-1 w-2/3 text-sm font-medium text-gray-700">
                    <input required disabled
                           class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                           type="text" v-model="amount" />
                  </div>
                </div>
                <div class="pt-5 px-4 overflow-auto">
                  <div class="flex space-y-1">
                    <DialogTitle class="text-lg font-medium text-gray-900">
                      Boletos
                    </DialogTitle>
                  </div>
                  <div class="flex w-full py-1">
                    <div class="px-4 py-3 w-1/3 text-sm font-medium text-gray-700">
                      Primer número
                    </div>
                    <div class="w-2/3 text-sm font-medium text-gray-700">
                      <input required
                             class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                             type="text" v-model="firstNumber" />
                    </div>
                  </div>
                  <div class="flex w-full">
                    <div class="px-4 py-3 w-1/3 text-sm font-medium text-gray-700">
                      Último número
                    </div>
                    <div class="w-2/3 text-sm font-medium text-gray-700">
                      <input required
                             class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                             type="text" v-model="lastNumber" />
                    </div>
                  </div>
                </div>
                <div class="pt-5 px-4 overflow-auto justify-self-end">
                  <button @click="newSale"
                          class="h-50 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                    Nueva venta
                  </button>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <TransitionRoot as="template" :show="showEditSale">
    <Dialog as="div" class="fixed inset-0 overflow-hidden" @close="showEditSale = false">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />
        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
                           enter-from="translate-x-full" enter-to="translate-x-0"
                           leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0"
                           leave-to="translate-x-full">
            <div class="bg-gray-50 w-screen max-w-2xl shadow-2xl overflow-auto">
              <div class="h-screen bg-gray-50 px-4 py-5">
                <div class="px-4 py-6">
                  <div class="flex items-start justify-between space-x-3">
                    <div class="space-y-1">
                      <DialogTitle class="text-lg font-medium text-gray-900">
                        Editar venta
                      </DialogTitle>
                    </div>
                    <div class="h-7 flex items-center">
                      <button type="button" class="text-gray-400 hover:text-gray-700" @click="showEditSale = false">
                        <span class="sr-only">Cerrar</span>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="flex w-full">
                  <div class="px-4 py-3 w-1/3 text-sm font-medium text-gray-700">
                    Numero de paquetes
                  </div>
                  <div class="px-4 pt-1 w-2/3 text-sm font-medium text-gray-700">
                    <input required
                           @change="updateTotal"
                           class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                           type="text" v-model="numberOfPackages" />
                  </div>
                </div>
                <div class="flex w-full">
                  <div class="px-4 py-3 w-1/3 text-sm font-medium text-gray-700">
                    Importe
                  </div>
                  <div class="px-4 pt-1 w-2/3 text-sm font-medium text-gray-700">
                    <input required disabled
                           class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                           type="text" v-model="amount" />
                  </div>
                </div>
                <div class="pt-5 px-4 overflow-auto">
                  <div class="flex space-y-1">
                    <DialogTitle class="text-lg font-medium text-gray-900">
                      Boletos
                    </DialogTitle>
                  </div>
                  <div class="flex w-full py-1">
                    <div class="px-4 py-3 w-1/3 text-sm font-medium text-gray-700">
                      Primer número
                    </div>
                    <div class="w-2/3 text-sm font-medium text-gray-700">
                      <input required
                             class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                             type="text" v-model="firstNumber" />
                    </div>
                  </div>
                  <div class="flex w-full">
                    <div class="px-4 py-3 w-1/3 text-sm font-medium text-gray-700">
                      Último número
                    </div>
                    <div class="w-2/3 text-sm font-medium text-gray-700">
                      <input required
                             class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                             type="text" v-model="lastNumber" />
                    </div>
                  </div>
                </div>
                <div class="pt-5 px-4 overflow-auto justify-self-end">
                  <button @click="updateSale"
                          class="h-50 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                    Guardar
                  </button>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

 </template>

 <script>
 import { useClient } from "@/use/client";

 import {defineAsyncComponent, reactive, ref} from "vue";

 const Loader = defineAsyncComponent(() => import("@/components/Common/Loader/Loader.vue"))
 import CardWithHeaderComponent from "@/components/Layout/Panels/CardWithHeaderComponent";
 import ToggleComponent from "../../components/Common/Form/ToggleComponent";
 import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
 import Pagination from "../../components/Pagination";
 import Notification from "@/App.vue";
 import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
 import CustomSelectInput from "../../components/Common/Select/CustomSelectInput";
 import moment from "moment";
 import ConfirmationAlertDelete from '../../components/Common/Confirmation/ConfirmationAlertDelete.vue';

 import {useUser} from "../../use/user";
 import {useCommercials} from "@/use/commercials";


 export default {
   name: "agenciesManagement",
   components: {
     Notification,
     Pagination,
     Loader,
     CardWithHeaderComponent,
     ToggleComponent,
     Dialog,
     DialogOverlay,
     DialogTitle,
     TransitionChild,
     TransitionRoot,
     CustomSelectInput,
     Listbox, ListboxButton, ListboxOption, ListboxOptions,
     ConfirmationAlertDelete

   },
   setup(props, context) {
     const alert = reactive({
       show: false,
     });

     return {
       user: useUser(),
       client: useClient(),
       commercials: useCommercials(),
       alert,
     }
   },
   data() {
     return {
       formatter: new Intl.NumberFormat('es-ES', {
         style: 'currency',
         currency: 'EUR'
       }),
       showCommercial: false,
       commercial: '',
       showNewSale: false,
       numberOfPackages: 0,
       amount: 0,
       firstNumber: '',
       lastNumber: '',
       showEditSale: false,
       saleId: '' ,
     }
   },
   methods: {
     save() {
       this.client.saveClientData()
     },

     getComercial() {
       this.commercials.filters.nombre = this.commercial
       this.commercials.searchCommercialsByFilters()
     },

     updateCommercialToClient(commercialId){
       this.$route.params.id
       this.client.updateCommercial(commercialId)
     },

     updateNewSaleTotal(){
       this.amount = this.numberOfPackages * 40;
     },

     openNewSale() {
       this.showNewSale = true
       this.numberOfPackages = 0
       this.amount = 0
       this.firstNumber = ''
       this.lastNumber = ''
     },

     newSale(){
       this.client.addNewSale(
           {
             clienteId: this.client.clientData.value.id,
             cantidad: this.numberOfPackages,
             precioUnitario: this.amount / this.numberOfPackages,
             total: this.amount,
             primerNumeroSorteo: this.firstNumber,
             ultimoNumeroSorteo: this.lastNumber,
           }
       )
     },

     editSale(sale){
       this.showEditSale = true
       this.saleId = sale.id
       this.numberOfPackages = sale.cantidad
       this.amount = sale.total
       this.firstNumber = sale.primer_numero_sorteo
       this.lastNumber = sale.ultimo_numero_sorteo
     },

     updateTotal(){
       this.sale.total = this.sale.cantidad * 40;
     },

     updateSale(){
       this.client.updateSale(
           {
             clienteId: this.client.clientData.value.id,
             id: this.saleId,
             cantidad: this.numberOfPackages,
             precioUnitario: this.amount / this.numberOfPackages,
             total: this.amount,
             primerNumeroSorteo: this.firstNumber,
             ultimoNumeroSorteo: this.lastNumber,
           }
       )
     },

     deleteSale(id){
       this.client.deleteSale({
         id: id,
         clienteId: this.client.clientData.value.id,
       })
     },

     getDateInFormat(date) {
       return moment(date).format('DD/MM/YYYY');
     },

     showIfHavePermissions(permission) {
       return this.user.permissions.value ? !!this.user.permissions.value.filter(e => e.name === permission).length : false
     },

   },
   async created() {
     await this.client.getClientData(this.$route.params.id)
   },
   mounted() {
   },


 }
 </script>