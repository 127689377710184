import SalesManagementService from "../../../services/Sales/SalesManagementService";

export default {
    state: {
        data: {
            sales: [],
            numberOfSales: 0,
            numberOfPages: 0,
            salesPerPages: 0
        },
    },
    mutations: {
        SET_SALES_DATA (state, salesData) {
            state.data = salesData
        },
    },
    actions: {
        index({ commit }){
            return SalesManagementService.index()
        },
        getSalesByFilters({ commit }, filters){
            return  SalesManagementService.index(filters)
        },
        storeSales({ commit }, SalesObject){
            commit('SET_SALES_DATA', SalesObject)
        }
    },
    getters: {
        getSalesData(state){
            console.log('SALES GETTER',state.data)

            return state.data
        },
    }
};